<template>
  <component
    :is="componentName"
    :column-key="columnKey"
    :copy-available="copyAvailable"
    :row="row"
  />
</template>

<script>
import { computed } from 'vue';
import DefaultCell from './DefaultTeamTableCell';
import ActionsCell from './ActionsCell';

const components = {
  default: DefaultCell,
  action: ActionsCell,
};

export default {
  emits: ['dataUpdate'],
  components,
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    copyAvailable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const componentName = computed(() => components[props.columnKey] || 'default');

    return {
      componentName,
    };
  },
};
</script>
