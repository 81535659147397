<template>
  <Modal
    class="manual-edit-modal"
    :visible="modalVisible"
    :title="modalTitle"
    @close="onEditModalClose"
    no-close-btn
  >
    <EditTeamModalContent
      :team-data="selectedRow || {}"
      :is-create="isManualEditCreateActive"
      @close-modal="onEditModalClose"
    />
  </Modal>
  <div class="manual-edit-root">
    <Table
      class="manual-edit-table"
      :columns="tableColumns"
      :rows="tableData"
      :loading="isDataLoading"
      :no-data-message="'No available teams'"
      @row-clicked="onRowEdit"
    >
      <template #cell="{ column, row }">
        <TeamTableCell
          :column-key="column.key"
          :copy-available="column.copyAvailable"
          :row="row"
        />
      </template>
    </Table>
    <Footer />
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Table from '@/components/common/Table';
import TeamTableCell from './table-configuration/TeamTableCell';
import Footer from '@/components/common/Footer';
import Modal from '@/components/common/Modal';
import EditTeamModalContent from './modals/EditTeamModalContent';

export default {
  components: {
    Table,
    TeamTableCell,
    Footer,
    Modal,
    EditTeamModalContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const tableColumns = computed(() => store.getters.manualEditTableColumns);
    const tableData = computed(() => store.getters.manualEditTableData);
    const isDataLoading = computed(() => store.getters.manualEditDataLoading);
    const isManualEditCreateActive = computed(() => store.getters.manualEditIsCreateNewRecordActive);
    const selectedRow = computed(() => store.getters.manualEditSelectedRow);
    const modalVisible = computed(() => !!selectedRow.value || isManualEditCreateActive.value);
    const modalTitle = computed(() => {
      if (isManualEditCreateActive.value) return 'Create new team';
      return selectedRow.value?.name;
    });
    const onRowEdit = (row) => {
      store.dispatch('updateManualEditSelectedRow', row);
    };
    const onEditModalClose = () => {
      store.dispatch('clearManualEditModalData');
    };

    onMounted(() => {
      const { query } = route;
      if (query.teamId) {
        store.dispatch('loadManualEditSingleTeam', {
          teamId: query.teamId,
          sportId: query.sport,
        }).then((res) => {
          store.dispatch('updateManualEditSelectedRow', res);
        });
      }
    });

    return {
      tableColumns,
      tableData,
      isDataLoading,
      selectedRow,
      modalTitle,
      modalVisible,
      isManualEditCreateActive,
      onRowEdit,
      onEditModalClose,
    };
  },

};
</script>

<style lang="scss">
.manual-edit-modal {
  .modal__dialog {
    min-width: 500px;
    max-width: 550px;
    .modal__header {
      padding: 16px;
      border-bottom: 1px solid $gray400;
      .modal__title {
        padding: 0;
      }
    }
  }
}
.manual-edit-root {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  .manual-edit-table {
    // Navbar height - 69px
    // Header height - 86px
    // Footer height - 57px
    height: calc(100vh - 69px - 86px - 57px);
  }
}
</style>
