<template>
  <div
    class="add-player-to-squad-search"
  >
    <div class="player-search-header">
      <Dropdown
        :label="selectedSearchPlayerByLabel"
      >
        <DropdownItem
          clickable
          @click="onSearchPlayerByChange('player-name')"
        >
          Player name
        </DropdownItem>
        <DropdownItem
          clickable
          @click="onSearchPlayerByChange('player-id')"
        >
          Player ID
        </DropdownItem>
        <DropdownItem
          clickable
          @click="onSearchPlayerByChange('player-index')"
        >
          Player index
        </DropdownItem>
      </Dropdown>
      <TextInput
        ref="searchRoot"
        class="search-field"
        :model-value="playerSearch"
        icon="search"
        placeholder="Search players"
        small
        @update:modelValue="updateSearch"
        @focus="onPlayerSearchInputFocus"
      />
    </div>
    <div :class="['player-search-body', { 'visible': playerSearchListVisible }]">
      <Spinner
        v-if="playersLoading"
        small
      />
      <p v-if="!playersLoading && !players.length">
        No players found
      </p>
      <div
        v-if="!playersLoading && players.length"
        class="player-items"
      >
        <div
          v-for="player in players"
          :key="player.playerId"
          class="player-item"
          @click="addPlayerToSquad(player)"
        >
          <div>{{ player.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  debounce, map, filter, includes,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    TextInput,
    Spinner,
    Dropdown,
    DropdownItem,
  },
  emits: ['onPlayerAdd'],
  props: {
    teamSquad: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchRoot = ref(null);
    const store = useStore();
    const playerSearch = ref('');
    const players = computed(() => filter(
      map(store.getters.manualEditPlayerSearchList, (data) => data.player),
      (searchPlayer) => !includes(
        map(props.teamSquad, (player) => player.playerId),
        searchPlayer.playerId,
      ),
    ));
    const playersLoading = computed(() => store.getters.manualEditPlayerSearchListLoading);
    const playerSearchListVisible = ref(false);
    const selectedSearchPlayerBy = ref('player-name');
    const selectedSearchPlayerByLabel = computed(() => {
      if (selectedSearchPlayerBy.value === 'player-id') {
        return 'Player ID';
      }
      if (selectedSearchPlayerBy.value === 'player-index') {
        return 'Player index';
      }
      return 'Player name';
    });

    const loadManualEditSearchPlayers = () => {
      store.dispatch('loadManualEditSearchPlayers', {
        searchValue: playerSearch.value,
        searchBy: selectedSearchPlayerBy.value,
        squadPlayersIds: map(props.teamSquad, (player) => player.playerId),
      });
    };

    const updateSearch = debounce((val) => {
      playerSearch.value = val;
      loadManualEditSearchPlayers();
    }, 500);

    const onPlayerSearchInputFocus = () => { playerSearchListVisible.value = true; };

    const addPlayerToSquad = (player) => { emit('onPlayerAdd', player); };

    onClickOutside(searchRoot, () => {
      playerSearchListVisible.value = false;
    });

    const onSearchPlayerByChange = (searchBy) => {
      selectedSearchPlayerBy.value = searchBy;
      loadManualEditSearchPlayers();
    };

    onMounted(() => loadManualEditSearchPlayers());

    return {
      searchRoot,
      playerSearch,
      players,
      playersLoading,
      updateSearch,
      playerSearchListVisible,
      selectedSearchPlayerByLabel,
      onPlayerSearchInputFocus,
      addPlayerToSquad,
      onSearchPlayerByChange,
    };
  },
};
</script>

<style lang="scss">
.add-player-to-squad-search {
  background-color: $white;
  position: relative;
  border-radius: 4px;

  .player-search-header {
    position: sticky;
    display: flex;
    align-items: center;
    gap: 8px;
    top: 0;
    left: 0;
    background-color: $white;
    .search-field {
      width: 100%;
    }
  }
  .player-search-body {
    width: 100%;
    height: 250px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 10;
    background-color: $white;
    border: 1px solid $gray500;
    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);

    &.visible {
      display: flex;
    }

    .player-items {
      width: 100%;
      overflow: auto;
      height: 100%;

      .player-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 8px;
        min-height: 50px;
        cursor: pointer;
        .add-player-to-squad-btn {
          display: none;
        }

        &:hover {
          background-color: $gray300;
          .add-player-to-squad-btn {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
